import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { settings } from "../utils/settings";
import AdminMenu from "./menus/AdminMenu";
import DeanMenu from "./menus/DeanMenu";
import ViceDeanMenu from "./menus/ViceDeanMenu";
import StudentMenu from "./menus/StudentMenu";
import TeacherMenu from "./menus/TeacherMenu";

const Header = () => {
  const navigate = useNavigate();

  let Role = localStorage.getItem("util");
  const data = JSON.parse(Role);

  return (
    <header>
      {!data && (
        <div className="header-top">
          <div className="container">
            <div className="row text-white">
              <div className="col-sm-10 d-flex justify-content-start align-items-center">
                <ul className="list-group list-group-horizontal list-group-flush">
                  <li
                    className="list-group-item bg-transparent text-white"
                    style={{ border: "none" }}
                  >
                    <span>
                      <i className="fa fa-phone" aria-hidden="true"></i>
                    </span>
                    &nbsp; Phone : &nbsp;
                    {settings.phone1}
                  </li>
                  <li
                    className="list-group-item bg-transparent text-white"
                    style={{ border: "none" }}
                  >
                    <span>
                      <i class="fa-regular fa-clock"></i>
                    </span>
                    &nbsp; Opening Time : &nbsp;
                    {settings.workSchedule}
                  </li>

                  <li
                    className="list-group-item bg-transparent text-white"
                    style={{ border: "none" }}
                  >
                    <span>
                      <i className="fa fa-home" aria-hidden="true"></i>
                    </span>
                    &nbsp; Address : &nbsp;
                    {settings.address}
                  </li>
                </ul>
              </div>
              <div className="col-sm-2 d-flex justify-content-end align-items-center">
                <Link to="/register" className="header_link me-2">
                  <i className="fa fa-registered" aria-hidden="true"></i>
                  &nbsp;Register
                </Link>
                <span>|</span>
                <Link to="/login" className="header_link ms-2">
                  <i className="fa fa-lock" aria-hidden="true"></i>&nbsp;Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}

      {data && data.util === 6982 && (
        <div className="header-auth">
          <div className="container">
            <div className="grid gap-8 d-flex justify-content-end align-items-center">
              <AdminMenu />
            </div>
          </div>
        </div>
      )}
      {data && data.util === 4984 && (
        <div className="header-auth">
          <div className="container">
            <div className="grid gap-8 d-flex justify-content-end align-items-center">
              <DeanMenu />
            </div>
          </div>
        </div>
      )}
      {data && data.util === 5487 && (
        <div className="header-auth">
          <div className="container">
            <div className="grid gap-8 d-flex justify-content-end align-items-center">
              <ViceDeanMenu />
            </div>
          </div>
        </div>
      )}
      {data && data.util === 2463 && (
        <div className="header-auth">
          <div className="container">
            <div className="grid gap-8 d-flex justify-content-end align-items-center">
              <TeacherMenu />
            </div>
          </div>
        </div>
      )}
      {data && data.util === 7528 && (
        <div className="header-auth">
          <div className="container">
            <div className="grid gap-8 d-flex justify-content-end align-items-center">
              <StudentMenu />
            </div>
          </div>
        </div>
      )}
      <div className="main-menu shadow-sm bg-body-tertiary">
        <Navbar expand="md" variant="light">
          <Container>
            <Navbar.Toggle aria-controls="offcanvasNavbar-expand-lg" />
            <Navbar.Brand
              className="pe-2"
              style={{ margin: "-1.3rem 0rem -0.9rem 0rem" }}
            >
              <Link to="/">
                <img src={logo} width="120" className="img-fluid" alt="logo" />
              </Link>
            </Navbar.Brand>

            <Navbar.Offcanvas
              id="offcanvasNavbar-expand-lg"
              aria-labelledby="offcanvasNavbarLabel-expand-lg"
              placement="start"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id="offcanvasNavbarLabel-expand-lg">
                  Main Menu
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-start flex-grow-1 fs-5 ps-3">
                  <Nav.Link onClick={() => navigate("/")}>Home</Nav.Link>
                  <Nav.Link onClick={() => navigate("/courses")}>
                    Courses
                  </Nav.Link>
                  <Nav.Link onClick={() => navigate("/events")}>
                    Events
                  </Nav.Link>
                  <Nav.Link onClick={() => navigate("/blog")}>Blog</Nav.Link>
                  <Nav.Link onClick={() => navigate("/contact")}>
                    Contact
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </div>
    </header>
  );
};

export default Header;
