import React from "react";
import logo from "../assets/images/logo_02.png";
import footer_post_01 from "../assets/images/blog/footer_post_01.jpg";
import footer_post_02 from "../assets/images/blog/footer_post_02.jpg";
import footer_post_03 from "../assets/images/blog/footer_post_03.jpg";
import gallery_01 from "../assets/images/sidebar/gallery_01.jpg";
import gallery_02 from "../assets/images/sidebar/gallery_02.jpg";
import gallery_03 from "../assets/images/sidebar/gallery_03.jpg";
import gallery_04 from "../assets/images/sidebar/gallery_04.jpg";
import gallery_05 from "../assets/images/sidebar/gallery_05.jpg";
import gallery_06 from "../assets/images/sidebar/gallery_06.jpg";
import gallery_07 from "../assets/images/sidebar/gallery_07.jpg";
import gallery_08 from "../assets/images/sidebar/gallery_08.jpg";

const Footer = () => {
  return (
    <footer>
      <div className="footer-top">
        <div className="container ">
          <div className="row">
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="footer-item">
                <div className="title d-flex justify-content-center">
                  <a href="/">
                    <img
                      src={logo}
                      style={{
                        height: 100,
                        margin: "-1.3rem 0rem -0.9rem 0rem",
                      }}
                      alt="logo"
                      className="img-responsive"
                    />
                  </a>
                </div>
                <div className="footer-about">
                  <p>
                    For the sake of our students' future success, our
                    institution is committed to providing them with an education
                    that places a premium on academic achievement
                  </p>
                  <ul className="list-group list-group-flush">
                    <li
                      className="list-group-item bg-transparent"
                      style={{ border: "none" }}
                    >
                      <span>
                        <i className="fa fa-home" aria-hidden="true"></i>
                      </span>{" "}
                      New Chokoya Road, USA.
                    </li>
                    <li
                      className="list-group-item bg-transparent"
                      style={{ border: "none" }}
                    >
                      <span>
                        <i className="fa fa-phone" aria-hidden="true"></i>
                      </span>{" "}
                      +8801 923 970 212, 0125897
                    </li>
                    <li
                      className="list-group-item bg-transparent"
                      style={{ border: "none" }}
                    >
                      <span>
                        <i
                          className="fa-regular fa-envelope"
                          aria-hidden="true"
                        ></i>
                      </span>{" "}
                      Contact@admin LabArtisan
                    </li>
                    <li
                      className="list-group-item bg-transparent"
                      style={{ border: "none" }}
                    >
                      <span>
                        <i className="fa fa-globe" aria-hidden="true"></i>
                      </span>{" "}
                      Email@admin LabArtisan
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="footer-item">
                <h4 className="title ms-4">Latest News</h4>
                <ul className="footer-post">
                  <li>
                    <div className="image">
                      <a href="single.html">
                        <img
                          src={footer_post_01}
                          alt="post image"
                          className="img-responsive"
                        />
                      </a>
                    </div>
                    <div className="content">
                      <p>
                        <a href="single.html">
                          The high school's robotics team recently competed in
                          the regional robotics competition and came out on top!
                        </a>
                      </p>
                      <span>4 February 2020</span>
                    </div>
                  </li>
                  <li>
                    <div className="image">
                      <a href="single.html">
                        <img
                          src={footer_post_02}
                          alt="post image"
                          className="img-responsive"
                        />
                      </a>
                    </div>
                    <div className="content">
                      <p>
                        <a href="single.html">
                          Several of the high school's top athletes recently
                          signed letters of intent to play for college teams
                          next year
                        </a>
                      </p>
                      <span>28 January 2021</span>
                    </div>
                  </li>
                  <li>
                    <div className="image">
                      <a href="single.html">
                        <img
                          src={footer_post_03}
                          alt="post image"
                          className="img-responsive"
                        />
                      </a>
                    </div>
                    <div className="content">
                      <p>
                        <a href="single.html">
                          The high school's drama club is gearing up for their
                          spring musical production, which will be held next
                          month
                        </a>
                      </p>
                      <span>3 January 2022</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="footer-item">
                <h4 className="title ms-5">Twitter Widget</h4>
                <ul className="twitter-post">
                  <li>
                    <div className="icon">
                      <i className="fab fa-twitter" aria-hidden="true"></i>
                    </div>
                    <div className="content">
                      <p>
                        Our school's basketball team just won their game against
                        our rivals! Congratulations to our athletes on their
                        hard work and dedication. #HighSchoolSports #SchoolPride
                        #Victory
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="fab fa-twitter" aria-hidden="true"></i>
                    </div>
                    <div className="content">
                      <p>
                        Annual talent show is coming up next week! Tickets are
                        available now in the main office. #HighSchoolEvents
                        #TalentShow #StudentPerformances
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="fab fa-twitter" aria-hidden="true"></i>
                    </div>
                    <div className="content">
                      <p>
                        The deadline to submit your applications for the summer
                        internship program is approaching quickly! If you're
                        interested in make sure to submit your application by
                        Friday, April 9th. #InternshipOpportunities
                        #CareerDevelopment #HighSchoolStudents
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2 col-sm-6 col-xs-12">
              <div className="footer-item">
                <h4 className="title ms-4">Recent Photos</h4>
                <ul className="photos">
                  <li>
                    <a href="#">
                      <img
                        src={gallery_01}
                        alt="gallery image"
                        className="img-responsive"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={gallery_02}
                        alt="gallery image"
                        className="img-responsive"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={gallery_03}
                        alt="gallery image"
                        className="img-responsive"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={gallery_04}
                        alt="gallery image"
                        className="img-responsive"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={gallery_05}
                        alt="gallery image"
                        className="img-responsive"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={gallery_06}
                        alt="gallery image"
                        className="img-responsive"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={gallery_07}
                        alt="gallery image"
                        className="img-responsive"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={gallery_08}
                        alt="gallery image"
                        className="img-responsive"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div
              className="col-md-6 col-sm-6 col-xs-12 fw-bold fs-4"
              style={{ fontFamily: "fa-style-family" }}
            >
              ©All Rights Reserved
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <ul className="social-default list-group list-group-horizontal list-group-flush">
                <li
                  className="list-group-item bg-transparent"
                  style={{ border: "none" }}
                >
                  <a href="#">
                    <i className="fab fa-facebook" aria-hidden="true"></i>
                  </a>
                </li>
                <li
                  className="list-group-item bg-transparent"
                  style={{ border: "none" }}
                >
                  <a href="#">
                    <i className="fab fa-dribbble" aria-hidden="true"></i>
                  </a>
                </li>
                <li
                  className="list-group-item bg-transparent"
                  style={{ border: "none" }}
                >
                  <a href="#">
                    <i className="fab fa-google-plus" aria-hidden="true"></i>
                  </a>
                </li>
                <li
                  className="list-group-item bg-transparent"
                  style={{ border: "none" }}
                >
                  <a href="#">
                    <i className="fab fa-twitter" aria-hidden="true"></i>
                  </a>
                </li>
                <li
                  className="list-group-item bg-transparent"
                  style={{ border: "none" }}
                >
                  <a href="#">
                    <i className="fab fa-pinterest" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
