import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Formik } from "formik";
import { Form, FloatingLabel } from "react-bootstrap";
import axiosInstance from "../../service/axiosInstance";
import * as Yup from "yup";

const schema = Yup.object().shape({
  name: Yup.string().required("Required"),
  subject: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  message: Yup.string().required("Required"),
});

const Contact = () => {
  return (
    <div>
      <section className="page-header">
        <div className="overlay">
          <Container className="text-white">
            <h1 className="text-center fw-bold">Contact us</h1>

            <Row className="justify-content-md-center">
              <Col md="auto">
                <Link to="/" style={{ textDecoration: "none", color: "white" }}>
                  Home
                </Link>
              </Col>
              <Col md="auto">-</Col>
              <Col md="auto">Contact us</Col>
            </Row>
          </Container>
        </div>
      </section>
      <section className="contact contact-page ">
        <div className="contact-details padding-120">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-6 col-xs-12">
                <ul>
                  <li className="contact-item">
                    <span className="icon fa-sharp fa-solid fa-location-dot"></span>
                    <div className="content">
                      <h4>Our Location</h4>
                      <p>
                        218 Sahera Tropical Center Room#7 <br /> New Elephant
                        Road 1205
                      </p>
                    </div>
                  </li>
                  <li className="contact-item">
                    <i className="icon fa fa-mobile-alt" aria-hidden="true"></i>

                    <div className="content">
                      <h4>Phone Number</h4>
                      <p>
                        01923-970212, 01776-502993 <br /> +2154897369
                      </p>
                    </div>
                  </li>
                  <li className="contact-item">
                    <i className="icon fa fa-envelope" aria-hidden="true"></i>
                    <div className="content">
                      <h4>Email Address</h4>
                      <p>
                        hello@labartisan <br /> hello@codexcoder.com
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  subject: "",
                  message: "",
                }}
                validationSchema={schema}
                onSubmit={(values, actions) => {
                  axiosInstance
                    .post("/contactMessages/save", values)
                    .then((response) => {
                      if (response.status === 200) {
                        toast.success(response.data.message, {
                          position: "top-center",
                          theme: "colored",
                          hideProgressBar: true,
                          autoClose: 1000,
                          closeOnClick: true,
                        });
                        actions.resetForm();
                      }
                    })
                    .catch((error) => {
                      {
                        !error.response.data.validations &&
                          toast.error(error.response.data.message, {
                            position: "top-center",
                            theme: "colored",
                            hideProgressBar: true,
                            autoClose: 1000,
                            closeOnClick: true,
                          });
                      }
                      {
                        error.response.data.validations &&
                          toast.error(error.response.data.validations.name, {
                            position: "top-center",
                            theme: "colored",
                            hideProgressBar: true,
                            autoClose: 1000,
                            closeOnClick: true,
                          });
                        toast.error(error.response.data.validations.email, {
                          position: "top-center",
                          theme: "colored",
                          hideProgressBar: true,
                          autoClose: 1000,
                          closeOnClick: true,
                        });
                        toast.error(error.response.data.validations.subject, {
                          position: "top-center",
                          theme: "colored",
                          hideProgressBar: true,
                          autoClose: 1000,
                          closeOnClick: true,
                        });
                        toast.error(error.response.data.validations.message, {
                          position: "top-center",
                          theme: "colored",
                          hideProgressBar: true,
                          autoClose: 1000,
                          closeOnClick: true,
                        });
                      }
                    });
                  actions.setSubmitting(false);
                }}
              >
                {(formikk) => (
                  <div
                    className="col-md-8 col-sm-6 col-xs-12"
                    onSubmit={formikk.handleSubmit}
                  >
                    <Form className="contact-form">
                      <Row>
                        <Col>
                          <FloatingLabel
                            controlId="name"
                            label="Your Name"
                            className="mb-4 "
                          >
                            <Form.Control
                              as="input"
                              type="text"
                              placeholder="Name"
                              onChange={formikk.handleChange}
                              onBlur={formikk.handleBlur}
                              value={formikk.values.name}
                              isInvalid={!!formikk.errors.name}
                            />
                            <Form.Control.Feedback type="invalid">
                              {formikk.errors.name}
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Col>
                        <Col>
                          <FloatingLabel
                            controlId="email"
                            label="Your Email"
                            className="mb-4 "
                          >
                            <Form.Control
                              as="input"
                              type="text"
                              placeholder="Email"
                              onChange={formikk.handleChange}
                              onBlur={formikk.handleBlur}
                              value={formikk.values.email}
                              isInvalid={!!formikk.errors.email}
                            />
                            <Form.Control.Feedback type="invalid">
                              {formikk.errors.email}
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Col>

                        <Col>
                          <FloatingLabel
                            controlId="subject"
                            label="Subject"
                            className="mb-4 "
                          >
                            <Form.Control
                              as="input"
                              type="text"
                              placeholder="Subject"
                              onChange={formikk.handleChange}
                              onBlur={formikk.handleBlur}
                              value={formikk.values.subject}
                              isInvalid={!!formikk.errors.subject}
                            />
                            <Form.Control.Feedback type="invalid">
                              {formikk.errors.subject}
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Col>
                      </Row>
                      <Col md="auto">
                        <FloatingLabel
                          controlId="message"
                          label="Message"
                          className="mb-4 "
                        >
                          <Form.Control
                            as="textarea"
                            type="text"
                            placeholder="Message"
                            style={{ height: 200 }}
                            onChange={formikk.handleChange}
                            onBlur={formikk.handleBlur}
                            value={formikk.values.message}
                            isInvalid={!!formikk.errors.message}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formikk.errors.message}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>

                      <Button
                        variant="primary"
                        className="fw-semibold"
                        onClick={formikk.submitForm}
                      >
                        Send Message
                      </Button>
                    </Form>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div className="map-area">
          <iframe
            allowfullscreen
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24176.994922677593!2d-73.90202769957703!3d40.759289326895654!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25fa191b4692b%3A0xe573e64fb17e324a!2sJackson%20Heights%2C%20Queens%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1643211772666!5m2!1sen!2sbd"
          ></iframe>
        </div>
      </section>
      <ToastContainer />
    </div>
  );
};

export default Contact;
