import React from "react";
import event01 from "../../assets/images/event/event_01.jpg";
import event02 from "../../assets/images/event/event_02.jpg";
import event03 from "../../assets/images/event/event_03.jpg";
import event04 from "../../assets/images/event/event_04.jpg";
import event05 from "../../assets/images/event/event_05.jpg";
import event06 from "../../assets/images/event/event_06.jpg";

const Events = () => {
  return (
    <div>
      <section class="page-header">
        <div class="overlay">
          <div class="container">
            <h3>Events Page</h3>
            <ul>
              <li>
                <a href="index.html">Home</a>
              </li>
              <li> - </li>
              <li>Event</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="event padding-120">
        <div className="container">
          <div className="event-items">
            <div className="row">
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="event-item">
                  <div className="event-image">
                    <img
                      src={event01}
                      alt="event image"
                      className="img-responsive"
                    />
                    <div className="date">
                      <span>24</span>
                      <p>March</p>
                    </div>
                  </div>
                  <div className="event-content">
                    <h4>Study Fastivel Event 2018</h4>
                    <ul>
                      <li>
                        <span>
                          <i
                            className="fa-sharp fa-solid fa-clock"
                            aria-hidden="true"
                          ></i>
                        </span>
                        08:00 am - 10:00 am
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-home" aria-hidden="true"></i>
                        </span>
                        218 New Elephant Road Dhaka
                      </li>
                    </ul>
                    <p>
                      Dratcaly novate fuly rarched an plication awesome theme
                      education that plication creative theme education.
                    </p>
                    <a href="event.html" className="button-default">
                      Join Now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="event-item">
                  <div className="event-image">
                    <img
                      src={event02}
                      alt="event image"
                      className="img-responsive"
                    />
                    <div className="date">
                      <span>24</span>
                      <p>March</p>
                    </div>
                  </div>
                  <div className="event-content">
                    <h4>Study Fastivel Event 2018</h4>
                    <ul>
                      <li>
                        <span>
                          <i
                            className="fa-sharp fa-solid fa-clock"
                            aria-hidden="true"
                          ></i>
                        </span>
                        08:00 am - 10:00 am
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-home" aria-hidden="true"></i>
                        </span>
                        218 New Elephant Road Dhaka
                      </li>
                    </ul>
                    <p>
                      Dratcaly novate fuly rarched an plication awesome theme
                      education that plication creative theme education.
                    </p>
                    <a href="event.html" className="button-default">
                      Join Now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="event-item">
                  <div className="event-image">
                    <img
                      src={event03}
                      alt="event image"
                      className="img-responsive"
                    />
                    <div className="date">
                      <span>24</span>
                      <p>March</p>
                    </div>
                  </div>
                  <div className="event-content">
                    <h4>Study Fastivel Event 2018</h4>
                    <ul>
                      <li>
                        <span>
                          <i
                            className="fa-sharp fa-solid fa-clock"
                            aria-hidden="true"
                          ></i>
                        </span>
                        08:00 am - 10:00 am
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-home" aria-hidden="true"></i>
                        </span>
                        218 New Elephant Road Dhaka
                      </li>
                    </ul>
                    <p>
                      Dratcaly novate fuly rarched an plication awesome theme
                      education that plication creative theme education.
                    </p>
                    <a href="event.html" className="button-default">
                      Join Now
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="event-item">
                  <div className="event-image">
                    <img
                      src={event04}
                      alt="event image"
                      className="img-responsive"
                    />
                    <div className="date">
                      <span>24</span>
                      <p>March</p>
                    </div>
                  </div>
                  <div className="event-content">
                    <h4>Study Fastivel Event 2018</h4>
                    <ul>
                      <li>
                        <span>
                          <i
                            className="fa-sharp fa-solid fa-clock"
                            aria-hidden="true"
                          ></i>
                        </span>
                        08:00 am - 10:00 am
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-home" aria-hidden="true"></i>
                        </span>
                        218 New Elephant Road Dhaka
                      </li>
                    </ul>
                    <p>
                      Dratcaly novate fuly rarched an plication awesome theme
                      education that plication creative theme education.
                    </p>
                    <a href="event.html" className="button-default">
                      Join Now
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="event-item">
                  <div className="event-image">
                    <img
                      src={event05}
                      alt="event image"
                      className="img-responsive"
                    />
                    <div className="date">
                      <span>24</span>
                      <p>March</p>
                    </div>
                  </div>
                  <div className="event-content">
                    <h4>Study Fastivel Event 2018</h4>
                    <ul>
                      <li>
                        <span>
                          <i
                            className="fa-sharp fa-solid fa-clock"
                            aria-hidden="true"
                          ></i>
                        </span>
                        08:00 am - 10:00 am
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-home" aria-hidden="true"></i>
                        </span>
                        218 New Elephant Road Dhaka
                      </li>
                    </ul>
                    <p>
                      Dratcaly novate fuly rarched an plication awesome theme
                      education that plication creative theme education.
                    </p>
                    <a href="event.html" className="button-default">
                      Join Now
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="event-item">
                  <div className="event-image">
                    <img
                      src={event06}
                      alt="event image"
                      className="img-responsive"
                    />
                    <div className="date">
                      <span>24</span>
                      <p>March</p>
                    </div>
                  </div>
                  <div className="event-content">
                    <h4>Study Fastivel Event 2018</h4>
                    <ul>
                      <li>
                        <span>
                          <i
                            className="fa-sharp fa-solid fa-clock"
                            aria-hidden="true"
                          ></i>
                        </span>
                        08:00 am - 10:00 am
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-home" aria-hidden="true"></i>
                        </span>
                        218 New Elephant Road Dhaka
                      </li>
                    </ul>
                    <p>
                      Dratcaly novate fuly rarched an plication awesome theme
                      education that plication creative theme education.
                    </p>
                    <a href="event.html" className="button-default">
                      Join Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Events;
