import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useStateValue } from "../../context/myContext";

const ViceDeanMenu = () => {
  const { myData } = useStateValue();
  const data = myData.userInfo;

  const [show, setShow] = useState(false);

  const toggleOffCanvas = () => {
    setShow((show) => !show);
  };

  const navigate = useNavigate();

  let name = localStorage.getItem("n");
  const data2 = JSON.parse(name);

  return (
    <div className="navbar">
      <Navbar expand="false" variant="light">
        <Navbar.Brand className="text-white text-white justify-content-center text-center">
          <span className="text-white ">Hi</span>
          &nbsp;
          <span className="text-white">{data2.n}</span>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="offcanvasNavbar-expand-false"
          className="fw-semibold text-white bg-primary"
          onClick={toggleOffCanvas}
        >
          Menu
        </Navbar.Toggle>

        <Navbar.Offcanvas
          id="offcanvasNavbar-expand-lg"
          aria-labelledby="offcanvasNavbarLabel-expand-lg"
          placement="start"
          show={show}
          onHide={toggleOffCanvas}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel-expand-lg">
              Main Menu
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-start flex-grow-1 fs-5 ps-3">
              <Nav.Link
                onClick={() => {
                  navigate("/lesson");
                  toggleOffCanvas();
                }}
              >
                Lesson Management
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  navigate("/teacher");
                  toggleOffCanvas();
                }}
              >
                Teacher Management
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  navigate("/student");
                  toggleOffCanvas();
                }}
              >
                Student Management
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  navigate("/contact-getAll");
                  toggleOffCanvas();
                }}
              >
                Contact Get All
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  navigate("/logout");
                  toggleOffCanvas();
                }}
              >
                Logout
              </Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Navbar>
    </div>
  );
};

export default ViceDeanMenu;
