import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Pagination from "react-bootstrap/Pagination";
import { Card, Col, Container, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import axiosInstance from "../../service/axiosInstance";

const ContactGetAll = () => {
  let Role = localStorage.getItem("util");
  const data = JSON.parse(Role);

  // Contact Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = "20";
  const [contactPage, setContactPage] = useState([]);
  const [contactPage2, setContactPage2] = useState([]);

  const getContactPage = () => {
    axiosInstance
      .get(
        "/contactMessages/getAll" +
          "?page=" + (currentPage - 1) + "&size=" + rowsPerPage+"&sort=id&type=desc"
      )
      .then((response) => {
        setContactPage(response.data);
        setContactPage2(response.data.content);
      });
  };

  useEffect(() => {
    if (data !== null) getContactPage();
  }, [currentPage]);

  const contactList = contactPage2;

  const totalPages = contactPage.totalPages;

  const showFirstPage = () => {
    let firstPage = 1;
    if (currentPage > firstPage) {
      setCurrentPage(firstPage);
    }
  };

  const showLastPage = () => {
    if (currentPage < Math.ceil(contactPage.totalElements / rowsPerPage)) {
      setCurrentPage(Math.ceil(contactPage.totalElements / rowsPerPage));
    }
  };

  const showNextPage = () => {
    if (currentPage < Math.ceil(contactPage.totalElements / rowsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const showPrevPage = () => {
    let prevPage = 1;
    if (currentPage > prevPage) {
      setCurrentPage(currentPage - prevPage);
    }
  };
  return (
    <div>
      {!data && <Navigate to="/login" />}
      {data &&
        data.util !== 4984 &&
        data.util !== 5487 &&
        data.util !== 6982 && <Navigate to="/login" />}

      {((data && data.util === 4984) ||
        (data && data.util === 5487) ||
        (data && data.util === 6982)) && (
        <div>
          {/* GetAll Contact Start*/}
          <Container fluid>
            <Card
              className="mb-5 mt-5 ms-3 me-3 text-center border border-3 shadow-sm bg-body rounded"
              border="warning"
            >
              <Card.Header className="fw-bold p-3" bg="primary" as="h5">
                Contact Message
              </Card.Header>
              <Card.Body>
                <Table responsive striped bordered hover>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Date</th>
                      <th>Subject</th>
                      <th>Message</th>
                    </tr>
                  </thead>
                  <tbody className="table-group-divider">
                    {contactList.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <span>{item.name}</span>
                        </td>

                        <td style={{ width: "17rem" }}>
                          <span>{item.email}</span>
                        </td>

                        <td style={{ width: "7rem" }}>
                          <span>{item.date}</span>
                        </td>

                        <td style={{ width: "20rem" }}>
                          <span>{item.subject}</span>
                        </td>
                        <td style={{ width: "20rem" }}>
                          <span>{item.message}</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Row>
                  <Col className="d-flex justify-content-start ps-5">
                    Page {currentPage} of {totalPages}
                  </Col>
                  <Col>
                    <Pagination className="d-flex justify-content-end pe-3">
                      <Pagination.First onClick={showFirstPage} />
                      <Pagination.Prev onClick={showPrevPage} />

                      <Pagination.Item active>{currentPage}</Pagination.Item>

                      <Pagination.Next onClick={showNextPage} />
                      <Pagination.Last onClick={showLastPage} />
                    </Pagination>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Container>
        </div>
      )}
      {/* GetAll Contact End*/}
    </div>
  );
};

export default ContactGetAll;
