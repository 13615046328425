import React from "react";
import course01 from "../../assets/images/courses/course_01.jpg";
import course02 from "../../assets/images/courses/course_02.jpg";
import course03 from "../../assets/images/courses/course_03.jpg";
import course04 from "../../assets/images/courses/course_04.jpg";
import course05 from "../../assets/images/courses/course_05.jpg";
import course06 from "../../assets/images/courses/course_06.jpg";
import course19 from "../../assets/images/courses/course_19.jpg";
import course20 from "../../assets/images/courses/course_20.jpg";
import course21 from "../../assets/images/courses/course_21.jpg";
import course22 from "../../assets/images/courses/course_22.jpg";
import course23 from "../../assets/images/courses/course_23.jpg";
import course24 from "../../assets/images/courses/course_24.jpg";

const Courses = () => {
  return (
    <div>
      <section className="page-header">
        <div className="overlay">
          <div className="container">
            <h3>Courses Column</h3>
            <ul>
              <li>
                <a href="index.html">Home</a>
              </li>
              <li> - </li>
              <li>Courses</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="courses padding-120">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="course-item">
                <div className="image">
                  <img
                    src={course01}
                    alt="course image"
                    className="img-responsive"
                  />
                  <span>
                    <sup>$</sup>200
                  </span>
                </div>
                <div className="content">
                  <h4>
                    <a href="course-single.html">Uficiently Dsenate Colabor</a>
                  </h4>
                  <ul>
                    <li>
                      <span>
                        <i className="fa fa-user" aria-hidden="true"></i>
                      </span>{" "}
                      <a href="#">Robot Smith</a>
                    </li>
                    <li>|</li>
                    <li>
                      <span>
                        <i className="fa fa-calendar" aria-hidden="true"></i>
                      </span>{" "}
                      <a href="#">24 April 2017 </a>
                    </li>
                  </ul>
                  <p>
                    Aolaboraively eenable vira niche markets Compelngly
                    evsculate funcnal mndshare through forand goaloriented
                  </p>
                  <div className="bottom">
                    <ul>
                      <li>
                        <span>
                          <i className="fa fa-users" aria-hidden="true"></i>
                        </span>{" "}
                        <a href="#">730</a>
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-comment" aria-hidden="true"></i>
                        </span>{" "}
                        <a href="#">350</a>
                      </li>
                    </ul>
                    <div className="course-rating">
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="course-item">
                <div className="image">
                  <img
                    src={course02}
                    alt="course image"
                    className="img-responsive"
                  />
                  <span>Free</span>
                </div>
                <div className="content">
                  <h4>
                    <a href="course-single.html">Awesome Solutions a Study</a>
                  </h4>
                  <ul>
                    <li>
                      <span>
                        <i className="fa fa-user" aria-hidden="true"></i>
                      </span>{" "}
                      <a href="#">Robot Smith</a>
                    </li>
                    <li>|</li>
                    <li>
                      <span>
                        <i className="fa fa-calendar" aria-hidden="true"></i>
                      </span>{" "}
                      <a href="#">24 April 2017 </a>
                    </li>
                  </ul>
                  <p>
                    Aolaboraively eenable vira niche markets Compelngly
                    evsculate funcnal mndshare through forand goaloriented
                  </p>
                  <div className="bottom">
                    <ul>
                      <li>
                        <span>
                          <i className="fa fa-users" aria-hidden="true"></i>
                        </span>{" "}
                        <a href="#">730</a>
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-comment" aria-hidden="true"></i>
                        </span>{" "}
                        <a href="#">350</a>
                      </li>
                    </ul>
                    <div className="course-rating">
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="course-item">
                <div className="image">
                  <img
                    src={course03}
                    alt="course image"
                    className="img-responsive"
                  />
                  <span>
                    <sup>$</sup>550
                  </span>
                </div>
                <div className="content">
                  <h4>
                    <a href="course-single.html">Disenatre Are Colaborative</a>
                  </h4>
                  <ul>
                    <li>
                      <span>
                        <i className="fa fa-user" aria-hidden="true"></i>
                      </span>{" "}
                      <a href="#">Robot Smith</a>
                    </li>
                    <li>|</li>
                    <li>
                      <span>
                        <i className="fa fa-calendar" aria-hidden="true"></i>
                      </span>{" "}
                      <a href="#">24 April 2017 </a>
                    </li>
                  </ul>
                  <p>
                    Aolaboraively eenable vira niche markets Compelngly
                    evsculate funcnal mndshare through forand goaloriented
                  </p>
                  <div className="bottom">
                    <ul>
                      <li>
                        <span>
                          <i className="fa fa-users" aria-hidden="true"></i>
                        </span>{" "}
                        <a href="#">730</a>
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-comment" aria-hidden="true"></i>
                        </span>{" "}
                        <a href="#">350</a>
                      </li>
                    </ul>
                    <div className="course-rating">
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="course-item">
                <div className="image">
                  <img
                    src={course04}
                    alt="course image"
                    className="img-responsive"
                  />
                  <span>
                    <sup>$</sup>500
                  </span>
                </div>
                <div className="content">
                  <h4>
                    <a href="course-single.html">Uficiently Dsenate Colabor</a>
                  </h4>
                  <ul>
                    <li>
                      <span>
                        <i className="fa fa-user" aria-hidden="true"></i>
                      </span>{" "}
                      <a href="#">Robot Smith</a>
                    </li>
                    <li>|</li>
                    <li>
                      <span>
                        <i className="fa fa-calendar" aria-hidden="true"></i>
                      </span>{" "}
                      <a href="#">24 April 2017 </a>
                    </li>
                  </ul>
                  <p>
                    Aolaboraively eenable vira niche markets Compelngly
                    evsculate funcnal mndshare through forand goaloriented
                  </p>
                  <div className="bottom">
                    <ul>
                      <li>
                        <span>
                          <i className="fa fa-users" aria-hidden="true"></i>
                        </span>{" "}
                        <a href="#">730</a>
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-comment" aria-hidden="true"></i>
                        </span>{" "}
                        <a href="#">350</a>
                      </li>
                    </ul>
                    <div className="course-rating">
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="course-item">
                <div className="image">
                  <img
                    src={course05}
                    alt="course image"
                    className="img-responsive"
                  />
                  <span>
                    <sup>$</sup>250
                  </span>
                </div>
                <div className="content">
                  <h4>
                    <a href="course-single.html">Awesome Solutions a Study</a>
                  </h4>
                  <ul>
                    <li>
                      <span>
                        <i className="fa fa-user" aria-hidden="true"></i>
                      </span>{" "}
                      <a href="#">Robot Smith</a>
                    </li>
                    <li>|</li>
                    <li>
                      <span>
                        <i className="fa fa-calendar" aria-hidden="true"></i>
                      </span>{" "}
                      <a href="#">24 April 2017 </a>
                    </li>
                  </ul>
                  <p>
                    Aolaboraively eenable vira niche markets Compelngly
                    evsculate funcnal mndshare through forand goaloriented
                  </p>
                  <div className="bottom">
                    <ul>
                      <li>
                        <span>
                          <i className="fa fa-users" aria-hidden="true"></i>
                        </span>{" "}
                        <a href="#">730</a>
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-comment" aria-hidden="true"></i>
                        </span>{" "}
                        <a href="#">350</a>
                      </li>
                    </ul>
                    <div className="course-rating">
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="course-item">
                <div className="image">
                  <img
                    src={course06}
                    alt="course image"
                    className="img-responsive"
                  />
                  <span>
                    <sup>$</sup>700
                  </span>
                </div>
                <div className="content">
                  <h4>
                    <a href="course-single.html">Disenatre Are Colaborative</a>
                  </h4>
                  <ul>
                    <li>
                      <span>
                        <i className="fa fa-user" aria-hidden="true"></i>
                      </span>{" "}
                      <a href="#">Robot Smith</a>
                    </li>
                    <li>|</li>
                    <li>
                      <span>
                        <i className="fa fa-calendar" aria-hidden="true"></i>
                      </span>{" "}
                      <a href="#">24 April 2017 </a>
                    </li>
                  </ul>
                  <p>
                    Aolaboraively eenable vira niche markets Compelngly
                    evsculate funcnal mndshare through forand goaloriented
                  </p>
                  <div className="bottom">
                    <ul>
                      <li>
                        <span>
                          <i className="fa fa-users" aria-hidden="true"></i>
                        </span>{" "}
                        <a href="#">730</a>
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-comment" aria-hidden="true"></i>
                        </span>{" "}
                        <a href="#">350</a>
                      </li>
                    </ul>
                    <div className="course-rating">
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="course-item">
                <div className="image">
                  <img
                    src={course19}
                    alt="course image"
                    className="img-responsive"
                  />
                  <span>
                    <sup>$</sup>200
                  </span>
                </div>
                <div className="content">
                  <h4>
                    <a href="course-single.html">Uficiently Dsenate Colabor</a>
                  </h4>
                  <ul>
                    <li>
                      <span>
                        <i className="fa fa-user" aria-hidden="true"></i>
                      </span>{" "}
                      <a href="#">Robot Smith</a>
                    </li>
                    <li>|</li>
                    <li>
                      <span>
                        <i className="fa fa-calendar" aria-hidden="true"></i>
                      </span>{" "}
                      <a href="#">24 April 2017 </a>
                    </li>
                  </ul>
                  <p>
                    Aolaboraively eenable vira niche markets Compelngly
                    evsculate funcnal mndshare through forand goaloriented
                  </p>
                  <div className="bottom">
                    <ul>
                      <li>
                        <span>
                          <i className="fa fa-users" aria-hidden="true"></i>
                        </span>{" "}
                        <a href="#">730</a>
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-comment" aria-hidden="true"></i>
                        </span>{" "}
                        <a href="#">350</a>
                      </li>
                    </ul>
                    <div className="course-rating">
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="course-item">
                <div className="image">
                  <img
                    src={course20}
                    alt="course image"
                    className="img-responsive"
                  />
                  <span>Free</span>
                </div>
                <div className="content">
                  <h4>
                    <a href="course-single.html">Awesome Solutions a Study</a>
                  </h4>
                  <ul>
                    <li>
                      <span>
                        <i className="fa fa-user" aria-hidden="true"></i>
                      </span>{" "}
                      <a href="#">Robot Smith</a>
                    </li>
                    <li>|</li>
                    <li>
                      <span>
                        <i className="fa fa-calendar" aria-hidden="true"></i>
                      </span>{" "}
                      <a href="#">24 April 2017 </a>
                    </li>
                  </ul>
                  <p>
                    Aolaboraively eenable vira niche markets Compelngly
                    evsculate funcnal mndshare through forand goaloriented
                  </p>
                  <div className="bottom">
                    <ul>
                      <li>
                        <span>
                          <i className="fa fa-users" aria-hidden="true"></i>
                        </span>{" "}
                        <a href="#">730</a>
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-comment" aria-hidden="true"></i>
                        </span>{" "}
                        <a href="#">350</a>
                      </li>
                    </ul>
                    <div className="course-rating">
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="course-item">
                <div className="image">
                  <img
                    src={course21}
                    alt="course image"
                    className="img-responsive"
                  />
                  <span>
                    <sup>$</sup>550
                  </span>
                </div>
                <div className="content">
                  <h4>
                    <a href="course-single.html">Disenatre Are Colaborative</a>
                  </h4>
                  <ul>
                    <li>
                      <span>
                        <i className="fa fa-user" aria-hidden="true"></i>
                      </span>{" "}
                      <a href="#">Robot Smith</a>
                    </li>
                    <li>|</li>
                    <li>
                      <span>
                        <i className="fa fa-calendar" aria-hidden="true"></i>
                      </span>{" "}
                      <a href="#">24 April 2017 </a>
                    </li>
                  </ul>
                  <p>
                    Aolaboraively eenable vira niche markets Compelngly
                    evsculate funcnal mndshare through forand goaloriented
                  </p>
                  <div className="bottom">
                    <ul>
                      <li>
                        <span>
                          <i className="fa fa-users" aria-hidden="true"></i>
                        </span>{" "}
                        <a href="#">730</a>
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-comment" aria-hidden="true"></i>
                        </span>{" "}
                        <a href="#">350</a>
                      </li>
                    </ul>
                    <div className="course-rating">
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="course-item">
                <div className="image">
                  <img
                    src={course22}
                    alt="course image"
                    className="img-responsive"
                  />
                  <span>
                    <sup>$</sup>500
                  </span>
                </div>
                <div className="content">
                  <h4>
                    <a href="course-single.html">Uficiently Dsenate Colabor</a>
                  </h4>
                  <ul>
                    <li>
                      <span>
                        <i className="fa fa-user" aria-hidden="true"></i>
                      </span>{" "}
                      <a href="#">Robot Smith</a>
                    </li>
                    <li>|</li>
                    <li>
                      <span>
                        <i className="fa fa-calendar" aria-hidden="true"></i>
                      </span>{" "}
                      <a href="#">24 April 2017 </a>
                    </li>
                  </ul>
                  <p>
                    Aolaboraively eenable vira niche markets Compelngly
                    evsculate funcnal mndshare through forand goaloriented
                  </p>
                  <div className="bottom">
                    <ul>
                      <li>
                        <span>
                          <i className="fa fa-users" aria-hidden="true"></i>
                        </span>{" "}
                        <a href="#">730</a>
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-comment" aria-hidden="true"></i>
                        </span>{" "}
                        <a href="#">350</a>
                      </li>
                    </ul>
                    <div className="course-rating">
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="course-item">
                <div className="image">
                  <img
                    src={course23}
                    alt="course image"
                    className="img-responsive"
                  />
                  <span>
                    <sup>$</sup>250
                  </span>
                </div>
                <div className="content">
                  <h4>
                    <a href="course-single.html">Awesome Solutions a Study</a>
                  </h4>
                  <ul>
                    <li>
                      <span>
                        <i className="fa fa-user" aria-hidden="true"></i>
                      </span>{" "}
                      <a href="#">Robot Smith</a>
                    </li>
                    <li>|</li>
                    <li>
                      <span>
                        <i className="fa fa-calendar" aria-hidden="true"></i>
                      </span>{" "}
                      <a href="#">24 April 2017 </a>
                    </li>
                  </ul>
                  <p>
                    Aolaboraively eenable vira niche markets Compelngly
                    evsculate funcnal mndshare through forand goaloriented
                  </p>
                  <div className="bottom">
                    <ul>
                      <li>
                        <span>
                          <i className="fa fa-users" aria-hidden="true"></i>
                        </span>{" "}
                        <a href="#">730</a>
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-comment" aria-hidden="true"></i>
                        </span>{" "}
                        <a href="#">350</a>
                      </li>
                    </ul>
                    <div className="course-rating">
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="course-item">
                <div className="image">
                  <img
                    src={course24}
                    alt="course image"
                    className="img-responsive"
                  />
                  <span>
                    <sup>$</sup>700
                  </span>
                </div>
                <div className="content">
                  <h4>
                    <a href="course-single.html">Disenatre Are Colaborative</a>
                  </h4>
                  <ul>
                    <li>
                      <span>
                        <i className="fa fa-user" aria-hidden="true"></i>
                      </span>{" "}
                      <a href="#">Robot Smith</a>
                    </li>
                    <li>|</li>
                    <li>
                      <span>
                        <i className="fa fa-calendar" aria-hidden="true"></i>
                      </span>{" "}
                      <a href="#">24 April 2017 </a>
                    </li>
                  </ul>
                  <p>
                    Aolaboraively eenable vira niche markets Compelngly
                    evsculate funcnal mndshare through forand goaloriented
                  </p>
                  <div className="bottom">
                    <ul>
                      <li>
                        <span>
                          <i className="fa fa-users" aria-hidden="true"></i>
                        </span>{" "}
                        <a href="#">730</a>
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-comment" aria-hidden="true"></i>
                        </span>{" "}
                        <a href="#">350</a>
                      </li>
                    </ul>
                    <div className="course-rating">
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Courses;
