import React, { useState } from "react";
import { Button, Col, Row, Carousel } from "react-bootstrap";
import banner01 from "../../assets/images/banner/banner_4.01.jpg";
import banner02 from "../../assets/images/banner/banner_4.02.jpg";
import banner03 from "../../assets/images/banner/banner_4.03.jpg";
import course01 from "../../assets/images/courses/course_01.jpg";
import course02 from "../../assets/images/courses/course_02.jpg";
import course03 from "../../assets/images/courses/course_03.jpg";
import course04 from "../../assets/images/courses/course_04.jpg";
import course05 from "../../assets/images/courses/course_05.jpg";
import course06 from "../../assets/images/courses/course_06.jpg";
import teacher01 from "../../assets/images/teachers/teacher_01.jpg";
import teacher02 from "../../assets/images/teachers/teacher_02.jpg";
import teacher03 from "../../assets/images/teachers/teacher_03.jpg";
import about from "../../assets/images/about/about.jpg";
import blog01 from "../../assets/images/blog/blog_01.jpg";
import blog02 from "../../assets/images/blog/blog_02.jpg";
import blog03 from "../../assets/images/blog/blog_03.jpg";
import event01 from "../../assets/images/event/event_01.jpg";
import event02 from "../../assets/images/event/event_02.jpg";
import event03 from "../../assets/images/event/event_03.jpg";

const Home = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <div>
      <Carousel
        nextIcon={false}
        prevIcon={false}
        controls={false}
        activeIndex={index}
        onSelect={handleSelect}
      >
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={banner01}
            alt="First slide"
            style={{ filter: "brightness(40%)" }}
          />
          <Carousel.Caption>
            <Row
              className="d-flex justify-content-start"
              style={{
                margin: "0rem 0rem 5rem -10.9rem",
                fontFamily: "fa-style-family",
              }}
            >
              <Col>
                <Row>
                  <Col lg="10">
                    <p
                      className="float-start ms-2"
                      style={{
                        fontSize: "3.2rem",
                      }}
                    >
                      Enjoy a prestigious and word-class education
                    </p>
                    {/* <h1
                      style={{
                        fontSize: "4rem",
                      }}
                      className="fw-bold float-start"
                    >
                      Best For Education
                    </h1>
                    <p
                      className="float-start mb-5"
                      style={{
                        fontSize: "2.2rem",
                      }}
                    >
                      Batter Education For Batter World
                    </p> */}
                  </Col>
                  <Col lg="8">
                    <Button className="ms-5 me-4">Read More</Button>
                    <Button>Buy Now</Button>
                  </Col>
                </Row>
              </Col>
              <Col lg="5"></Col>
            </Row>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={banner02}
            alt="Second slide"
            style={{ filter: "brightness(50%)" }}
          />

          <Carousel.Caption>
            <Row
              className="d-flex justify-content-start"
              style={{
                margin: "0rem 0rem 5rem -10.9rem",
                fontFamily: "fa-style-family",
              }}
            >
              <Col>
                <Row>
                  <Col lg="10">
                    <p
                      className="float-start ms-2"
                      style={{
                        fontSize: "2.9rem",
                      }}
                    >
                      Unleash your full potential in an inspiring learning
                      environment
                    </p>
                    {/* <h1
                      style={{
                        fontSize: "4rem",
                      }}
                      className="fw-bold float-start"
                    >
                      Best For Education
                    </h1>
                    <p
                      className="float-start mb-5"
                      style={{
                        fontSize: "2.2rem",
                      }}
                    >
                      Batter Education For Batter World
                    </p> */}
                  </Col>
                  <Col lg="9">
                    <Button className="ms-5 me-4">Read More</Button>
                    <Button>Buy Now</Button>
                  </Col>
                </Row>
              </Col>
              <Col lg="5"></Col>
            </Row>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={banner03}
            alt="Third slide"
            style={{ filter: "brightness(50%)" }}
          />

          <Carousel.Caption>
            <Row
              className="d-flex justify-content-start"
              style={{
                margin: "0rem 0rem 5rem -10.9rem",
                fontFamily: "fa-style-family",
              }}
            >
              <Col>
                <Row>
                  <Col lg="10">
                    <p
                      className="float-start ms-2"
                      style={{
                        fontSize: "2.9rem",
                      }}
                    >
                      Managementon Schools: Where education meets opportunity
                    </p>
                    {/* <h1
                      style={{
                        fontSize: "4rem",
                      }}
                      className="fw-bold float-start"
                    >
                      Best For Education
                    </h1>
                    <p
                      className="float-start mb-5"
                      style={{
                        fontSize: "2.2rem",
                      }}
                    >
                      Batter Education For Batter World
                    </p> */}
                  </Col>
                  <Col lg="9">
                    <Button className="ms-5 me-4">Read More</Button>
                    <Button>Buy Now</Button>
                  </Col>
                </Row>
              </Col>
              <Col lg="5"></Col>
            </Row>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      <section className="services padding-120">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="service-item">
                <span className="icon fa-solid fa-graduation-cap mb-3 mt-3"></span>
                <h4>Qualified Lecturers</h4>
                <p>
                  Our private high school is proud to have a team of highly
                  qualified lecturers with years of experience in their fields.
                </p>
                <a href="services.html">
                  Read More{" "}
                  <i
                    className="fa fa-angle-double-right"
                    aria-hidden="true"
                  ></i>
                </a>
                <br />
                <br />
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="service-item">
                <span className="icon fa-solid fa-book-open mb-3 mt-3"></span>
                <h4>Libraries & Laboratories</h4>
                <p>
                  The Managementon School has all the necessary resources, such
                  as libraries and laboratories, to help students succeed in the
                  technical and cultural field.
                </p>
                <a href="services.html">
                  Read More{" "}
                  <i
                    className="fa fa-angle-double-right"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="service-item">
                <span className="icon fa-solid fa-bars mt-3 mb-3"></span>
                <h4>Financial Aid</h4>
                <p>
                  Managementon school is committed to assisting students with
                  need-based financial aid to make college more affordable for
                  families.
                </p>
                <a href="services.html">
                  Read More{" "}
                  <i
                    className="fa fa-angle-double-right"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="courses section-bg padding-120">
        <div className="container">
          <div className="section-header">
            <h3>Our Courses</h3>
            <p>
              Our courses prepare students for the best future by providing
              up-to-date content, a professional and scientific approach, and a
              rich experience empowered with superior teaching techniques.
            </p>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="course-item">
                <div className="image">
                  <img
                    src={course01}
                    alt="course image"
                    className="img-responsive"
                  />
                </div>
                <div className="content">
                  <h4>
                    <a href="course-single.html">Mathematics</a>
                  </h4>
                  <p>
                    This course will help you develop in-demand logical and
                    analytical reasoning, problem-solving, and computer skills.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="course-item">
                <div className="image">
                  <img
                    src={course02}
                    alt="course image"
                    className="img-responsive"
                  />
                </div>
                <div className="content">
                  <h4>
                    <a href="course-single.html">Physics</a>
                  </h4>
                  <p>
                    Thanks to this course, students will gain the ability to
                    interpret better and evaluate the developments in physical
                    sciences.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="course-item">
                <div className="image">
                  <img
                    src={course03}
                    alt="course image"
                    className="img-responsive"
                  />
                </div>
                <div className="content">
                  <h4>
                    <a href="course-single.html">English</a>
                  </h4>
                  <p>
                    This course teaches language use, field terminology,
                    punctuation, and writer's intent across different text
                    genres.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="course-item">
                <div className="image">
                  <img
                    src={course04}
                    alt="course image"
                    className="img-responsive"
                  />
                </div>
                <div className="content">
                  <h4>
                    <a href="course-single.html">History</a>
                  </h4>
                  <p>
                    This course not only provides knowledge but also gives you
                    an inclusive approach to domestic and international history.
                  </p>
                </div>
                <br />
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="course-item">
                <div className="image">
                  <img
                    src={course05}
                    alt="course image"
                    className="img-responsive"
                  />
                </div>
                <div className="content">
                  <h4>
                    <a href="course-single.html">The Arts</a>
                  </h4>
                  <p>
                    In addition to the physical sciences, we also provide a
                    fascinating education in the artistic arts to develop our
                    pupils' creative and intuitive talents.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="course-item">
                <div className="image">
                  <img
                    src={course06}
                    alt="course image"
                    className="img-responsive"
                  />
                </div>
                <div className="content">
                  <h4>
                    <a href="course-single.html">Chemistry</a>
                  </h4>
                  <p>
                    The chemistry program at our high school is challenging and
                    prepares students for college and beyond.
                  </p>
                </div>
                <br />
              </div>
            </div>
          </div>
          <Row className="justify-content-md-center">
            <Col lg="2">
              <Button>
                <a href="courses.html" className="text-white">
                  View All Courses
                </a>
              </Button>
            </Col>
          </Row>
        </div>
      </section>

      <section className="achievements section-notch">
        <div className="overlay padding-120">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-6 col-xs-12">
                <div className="achievement-item">
                  <i className="icon fa-solid fa-graduation-cap mt-3 mb-3"></i>
                  <span className="counter">2500</span>
                  <p>Students Enrolled</p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12">
                <div className="achievement-item">
                  <i className="icon fa-solid fa-trophy mt-3 mb-3"></i>
                  <span className="counter">912</span>
                  <p>Best Awards Won</p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12">
                <div className="achievement-item">
                  <i className="icon fa-solid fa-pen-ruler mt-3 mb-3"></i>
                  <span className="counter">370</span>
                  <p>Classes Completed</p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12">
                <div className="achievement-item">
                  <i className="icon fa-solid fa-book-open-reader mt-3 mb-3"></i>
                  <span className="counter">648</span>
                  <p>Our Total Courses</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="teachers padding-120">
        <div className="container">
          <div className="section-header">
            <h3>Meet Our Teachers</h3>
            <p>
              We are proud of our staff of highly qualified and dedicated
              instructors that are dedicated to providing our students with the
              best education possible.
            </p>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="teacher-item">
                <div className="teacher-image">
                  <img
                    src={teacher01}
                    alt="teacher image"
                    className="img-responsive"
                  />
                </div>
                <div className="teacher-content">
                  <h4>
                    <a href="teacher-profile.html">Robot Jhonson</a>
                  </h4>
                  <span>Math Teacher</span>
                  <ul className="teacher-share">
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-vimeo" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-pinterest" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-rss" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="teacher-item">
                <div className="teacher-image">
                  <img
                    src={teacher02}
                    alt="teacher image"
                    className="img-responsive"
                  />
                </div>
                <div className="teacher-content">
                  <h4>
                    <a href="teacher-profile.html">Janaton Doe</a>
                  </h4>
                  <span>English Teacher</span>
                  <ul className="teacher-share">
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-vimeo" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-pinterest" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-rss" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="teacher-item">
                <div className="teacher-image">
                  <img
                    src={teacher03}
                    alt="teacher image"
                    className="img-responsive"
                  />
                </div>
                <div className="teacher-content">
                  <h4>
                    <a href="teacher-profile.html">Alex Jhonson</a>
                  </h4>
                  <span>Art Teacher</span>
                  <ul className="teacher-share">
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-vimeo" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-pinterest" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-rss" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-two">
        <div className="left">
          <div className="left-in">
            <div className="title">
              <div className="t-left">
                <h2>10+</h2>
              </div>
              <div className="t-right mt-2">
                <h4>Years of professional teaching experience</h4>
              </div>
            </div>
            <ul>
              <li>
                <div className="icon fa-solid fa-file-circle-check"></div>

                <div className="content mt-3">
                  <h4>10.000 + Free Tutorials</h4>
                  <p>
                    Innovative technology techniques in high school education{" "}
                  </p>
                </div>
              </li>
              <li>
                <div className="icon fa-solid fa-chalkboard"></div>
                <div className="content mt-3">
                  <h4>100+ Courses </h4>
                  <p>Prepare you effectively for higher study </p>
                </div>
              </li>
              <li>
                <div className="icon fa-solid fa-tablet-button"></div>
                <div className="content mt-2">
                  <h4>1000+ Free documents and eBook Available</h4>
                  <p>
                    Certified original documents that have been expertly
                    produced{" "}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="right">
          <img src={about} alt="about image" className="img-responsive" />
        </div>
      </section>

      <section className="blog padding-120">
        <div className="container">
          <div className="section-header">
            <h3>From Our Blog</h3>
            <p>
              Our blog page, which we have created to encourage our students to
              blog, is filled with engaging content
            </p>
          </div>
          <div className="blog-items">
            <div className="row">
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="blog-item">
                  <div className="blog-image">
                    <a href="single.html">
                      <img
                        src={blog01}
                        alt="blog image"
                        className="img-responsive"
                      />
                    </a>
                  </div>
                  <div className="blog-content">
                    <h4>
                      <a href="single.html">
                        Opportunities Brought by AI in Education
                      </a>
                    </h4>
                    <p>
                      This essay explores the potential educational applications
                      of AI models
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="blog-item">
                  <div className="blog-image">
                    <a href="single.html">
                      <img
                        src={blog02}
                        alt="blog image"
                        className="img-responsive"
                      />
                    </a>
                  </div>
                  <div className="blog-content">
                    <h4>
                      <a href="single.html">How to Gain The Habit of Writing</a>
                    </h4>
                    <p>
                      This article emphasizes the importance of developing the
                      skill of writing in high school
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="blog-item">
                  <div className="blog-image">
                    <a href="single.html">
                      <img
                        src={blog03}
                        alt="blog image"
                        className="img-responsive"
                      />
                    </a>
                  </div>
                  <div className="blog-content">
                    <h4>
                      <a href="single.html">
                        Importance and Benefits of Analytical Thinking
                      </a>
                    </h4>
                    <p>
                      This article emphasizes the need for acquiring analytical
                      thinking at the high school age
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="event padding-120">
        <div className="container">
          <div className="section-header">
            <h3>Our Upcoming Events</h3>
            <p>
              We continually organize social activities for our students in the
              school setting
            </p>
          </div>
          <div className="event-items">
            <div className="row">
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="event-item">
                  <div className="event-image">
                    <img
                      src={event01}
                      alt="event image"
                      className="img-responsive"
                    />
                  </div>
                  <div className="event-content">
                    <h4>Spring Festival Event 2020</h4>

                    <p>
                      We organized many events encouraging healthy social and
                      cultural interaction among our students across our first
                      festival in 2020
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="event-item">
                  <div className="event-image">
                    <img
                      src={event02}
                      alt="event image"
                      className="img-responsive"
                    />
                  </div>
                  <div className="event-content">
                    <h4>Spring Festival Event 2021</h4>
                    <p>
                      Our second festival hosted different events in a much
                      broader framework with the experience we gained from the
                      first
                    </p>
                    <br />
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="event-item">
                  <div className="event-image">
                    <img
                      src={event03}
                      alt="event image"
                      className="img-responsive"
                    />
                  </div>
                  <div className="event-content">
                    <h4>Spring Festival Event 2022</h4>
                    <p>
                      The festival we held last year witnessed great excitement
                      and enthusiasm
                    </p>
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
